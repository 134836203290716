<template>
  <b-card title="Rekap Bulanan">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col cols="12" md="3">
                <b-form-group label="Periode" label-for="date">
                  <validation-provider #default="{ errors }" name="date" rules="required">
                    <b-form-input id="date" v-model="dataParent.date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Tanggal" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <validation-provider #default="{ errors }" name="class_id" rules="required">
                  <b-form-group label="Kelas" label-for="class_id" :state="errors.length > 0 ? false : null">
                    <v-select id="class_id" v-model="dataParent.class_id" :reduce="(class_id) => class_id.id"
                      placeholder="Pilih Kelas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass"
                      label="class_desc" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="GetAllData">
                    View
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1"
                    @click="ExportExcel">
                    Export
                  </b-button>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>

    <table class="table table-striped table-bordered" id="recaptable">
      <thead>
        <tr>
          <th>No</th>
          <th>NISN</th>
          <th>Nama Lengkap</th>
          <th>L/P</th>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>6</th>
          <th>7</th>
          <th>8</th>
          <th>9</th>
          <th>10</th>
          <th>11</th>
          <th>12</th>
          <th>13</th>
          <th>14</th>
          <th>15</th>
          <th>16</th>
          <th>17</th>
          <th>18</th>
          <th>19</th>
          <th>20</th>
          <th>21</th>
          <th>22</th>
          <th>23</th>
          <th>24</th>
          <th>25</th>
          <th>26</th>
          <th>27</th>
          <th>28</th>
          <th>29</th>
          <th>30</th>
          <th>31</th>
          <th>H</th>
          <th>I</th>
          <th>S</th>
          <th>A</th>
          <th>SK</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr v-for="item in DataProduct" :key="item.no">
          <td>{{ item.no }}</td>
          <td>{{ item.periode_date }}</td>
          <td>{{ item.component_name }}</td>
          <td>{{ item.total }}</td>
        </tr> -->
      </tbody>
    </table>
  </b-card>
</template>

<script>
import moment from 'moment';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import axios from 'axios';
import $ from 'jquery';

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data: function () {
    return {
      products: [],
    }
  },
  directives: {
    Ripple,
  },
  mounted() {
    $('#recaptable').DataTable({
      processing: true,
      responsive: true,
      scrollX: true,
    });
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      codeAdvance,
      columns: [
        {
          label: 'NIP',
          field: 'employee_id',
        },
        {
          label: 'Nama Lengkap',
          field: 'name',
        },
        {
          label: 'L/P',
          field: 'gender',
        },
        {
          label: 'Aktif',
          field: 'is_active',
        },
        {
          label: 'Jabatan',
          field: 'employee_assignments.data.position.name',
        },
        {
          label: 'Masa Kerja',
          field: 'working_period',
        },
        {
          label: 'Usia',
          field: 'age',
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      modalRemove: '',
      dataParent: {
        date: this.NowDateFormat(),
        class_id: ''
      },
      DataProduct: [],
      listClass: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    // this.GetAllData()
    this.getClass()
  },
  methods: {
    NowDateFormat() {
      var NowDate = Date.now();
      var d = new Date(NowDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    async getClass() {
      try {
        const response = await this.$http.get('/masterclasses')
        this.listClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Data.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-pegawai-add' })
    },
    async Filter() {
      try {
        var Period = document.getElementById('periode_date');
        const PeriodeDate = Period.value.substring(0, 7);
        console.log(PeriodeDate)
        //this.$router.push({ name: 'laporan-rekap-gaji-filter', params: { id: PeriodeDate } })
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },

    async ExportExcel() {
      var table = $('#recaptable').DataTable();
      table.page.len(-1).draw();
      window.open('data:application/vnd.ms-excel,' +
        encodeURIComponent($('#recaptable').parent().html()));
      setTimeout(function () {
        table.page.len(10).draw();
      }, 1000)
    },

    replaceByValue(field, oldvalue, newvalue) {
      for (var k = 0; k < json.length; ++k) {
        if (oldvalue == json[k][field]) {
          json[k][field] = newvalue;
        }
      }
      return json;
    },

    async GetAllData() {
      try {
        var Period = document.getElementById('date');
        const PeriodeDate = Period.value.substring(0, 7);
        var dataclass = this.dataParent.class_id;
        const response = await this.$http.get(`/studentpresence/recap-monthly?month=${PeriodeDate}&class_id=${dataclass}`)
        var DataRespone = response.data.data;
        var number = 1;
        var RecapTemp = [];
        var RecapDaily = [];
        $.each(DataRespone, function (i, item) {
          var DataDaily = item.daily_statuses;
          var DailyAbsen = {
            "no": number,
            "nisn": item.student.nisn,
            "name": item.student.name,
            "gender": item.student.gender,
            "hadir": item.total_statuses.hadir,
            "izin": item.total_statuses.izin,
            "sakit": item.total_statuses.sakit,
            "alpa": item.total_statuses.alpa,
            "skors": item.total_statuses.skors,
            "01": "",
            "02": "",
            "03": "",
            "04": "",
            "05": "",
            "06": "",
            "07": "",
            "08": "",
            "09": "",
            "10": "",
            "11": "",
            "12": "",
            "13": "",
            "14": "",
            "15": "",
            "16": "",
            "17": "",
            "18": "",
            "19": "",
            "20": "",
            "21": "",
            "22": "",
            "23": "",
            "24": "",
            "25": "",
            "26": "",
            "27": "",
            "28": "",
            "29": "",
            "30": "",
            "31": "",
          }

          $.each(DataDaily, function (j, itemabsensi) {
            const vueDate = itemabsensi.date;
            const date = new Date(vueDate);
            var hari = moment(date).format('DD')
            var tipeKehadiran = ''
            if (itemabsensi.type == 1) {
              tipeKehadiran = 'H'
            } else if (itemabsensi.type == 2) {
              tipeKehadiran = 'I'
            } else if (itemabsensi.type == 3) {
              tipeKehadiran = 'S'
            } else if (itemabsensi.type == 4) {
              tipeKehadiran = 'A'
            } else if (itemabsensi.type == 5) {
              tipeKehadiran = 'SK'
            }

            DailyAbsen[hari] = tipeKehadiran;

          });

          console.log(DailyAbsen)
          RecapTemp.push(DailyAbsen);
          number++;
        });
        this.DataProduct = this.RecapTemp;

        $('#recaptable').DataTable().destroy();
        var myTable = $('#recaptable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "responsive": true,
          "scrollX": true,
          "data": [],
          "columns": [{
            "title": "No",
            "data": "no"
          }, {
            "title": "NISN",
            "data": "nisn"
          }, {
            "title": "Nama Lengkap",
            "data": "name"
          }, {
            "title": "L/P",
            "data": "gender"
          }, {
            "title": "1",
            "data": "01"
          }, {
            "title": "2",
            "data": "02"
          }, {
            "title": "3",
            "data": "03"
          }, {
            "title": "4",
            "data": "04"
          }, {
            "title": "5",
            "data": "05"
          }, {
            "title": "6",
            "data": "06"
          }, {
            "title": "7",
            "data": "07"
          }, {
            "title": "8",
            "data": "08"
          }, {
            "title": "9",
            "data": "09"
          }, {
            "title": "10",
            "data": "10"
          }, {
            "title": "11",
            "data": "11"
          }, {
            "title": "12",
            "data": "12"
          }, {
            "title": "13",
            "data": "13"
          }, {
            "title": "14",
            "data": "14"
          }, {
            "title": "15",
            "data": "15"
          }, {
            "title": "16",
            "data": "16"
          }, {
            "title": "17",
            "data": "17"
          }, {
            "title": "18",
            "data": "18"
          }, {
            "title": "19",
            "data": "19"
          }, {
            "title": "20",
            "data": "20"
          }, {
            "title": "21",
            "data": "21"
          }, {
            "title": "22",
            "data": "22"
          }, {
            "title": "23",
            "data": "23"
          }, {
            "title": "24",
            "data": "24"
          }, {
            "title": "25",
            "data": "25"
          }, {
            "title": "26",
            "data": "26"
          }, {
            "title": "27",
            "data": "27"
          }, {
            "title": "28",
            "data": "28"
          }, {
            "title": "29",
            "data": "29"
          }, {
            "title": "30",
            "data": "30"
          }, {
            "title": "31",
            "data": "31"
          }, {
            "title": "H",
            "data": "hadir"
          }, {
            "title": "I",
            "data": "izin"
          }, {
            "title": "S",
            "data": "sakit"
          }, {
            "title": "A",
            "data": "alpa"
          }, {
            "title": "SK",
            "data": "skors"
          }],
          columnDefs: [
            {
              targets: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,32,33,34], // Index of the column (zero-based) where you want to display the icon
              render: function (data, type, row, meta) {
                if (!data) {
                  return '<i class="fa fa-times-circle" style="color: #ec9418;"></i>'; // Replace with your desired icon HTML
                } else {
                  return data; // Display the original data if it's not empty
                }
              }
            }
          ],
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          myTable.row.add(value);
        });
        myTable.draw();
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeParent(val) {
      try {
        console.log(val)
        await this.$http.delete(`/employees/${val}`)
        await this.getAllEmployee()
        this.showToast('success', 'top-center', 'Delete Data Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Data Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
